<template>
	<div class="ht-detail">
		<div class="container">
			<div class="container-left">
				<!--左边多张图片-->
				<div class="hotel-imgs">
					<div class="imgs-top">
						<p>{{ detalisInfo.jdmc }}</p>
						<el-rate :colors="['#FF9524', '#FF9524', '#FF9524']" :disabled="true"
							:value="Number(detalisInfo.category)"></el-rate>
					</div>
					<div class="imgs-center">
						<i class="el-icon-location-outline"></i>
						<p>{{ detalisInfo.address }}</p>
					</div>
					<div class="imgs-bottom">
						<div class="imgs-left" @click="selectImg(0)">
							<!-- <el-image :src="detalisInfo.img" class="img" :preview-src-list="objArrImg">
											<div slot="error" class="errImg">
														<img class="errImg" :src="errImgB" alt="">
											</div>
							</el-image> -->
							<img :src="detalisInfo.img" class="img" alt="">
							<span>查看所有的图片（{{ objArrImg.length }}张)</span>
						</div>
						<div class="ims-right">
							<!-- :preview-src-list="objArrImg" -->
							<el-image v-for="(item, idx) in objArrImgSplice" 
									:key="item.index" :src="item.img" class="img"  @click="selectImg(idx + 1)" v-show="item">
								<div slot="error" style="width:100%;height:100%">
											<img class="errImg" :src="errImg" alt="">
								</div>
							</el-image>
						</div>
					</div>
				</div>
				<!--左边底部酒店信息-->
				<div class="left-tabs">
					<span :class="{ to_active: tabsShow === 1 }" @click="handleTab(1)">房型</span>
					<span :class="{ to_active: tabsShow === 2 }" @click="handleTab(2)">酒店信息</span>
					<span :class="{ to_active: tabsShow === 3 }" @click="handleTab(3)">交通</span>

					<el-button round  size="small" style="padding: 8px 15px;text-align: center;"  :class="{clickbtn: travelStandardsFilter }" @click="changetravelStandardsFilter()" >
						符合差标
					</el-button>

				</div>
				<!--左边底部酒店搜索模块-->
				<div class="home-search left-tabs" v-if="tabsShow == 1">
					<el-date-picker v-model="dateValue" type="daterange" format="yyyy-MM-dd"
						:picker-options="pickerOptions(bookDateRange.minDay,bookDateRange.maxDay)" value-format="yyyy-MM-dd" range-separator="至"
						start-placeholder="入住日期" end-placeholder="结束日期">
					</el-date-picker>
					<el-select v-model="hotelSelectValue" placeholder="请选择酒店" style="
							width: 150px;
							margin-left: 20px;
							margin-right: 10px;
						">
						<el-option v-for="item in options" :key="item.fyid" :label="item.label" :value="item.fyid">
						</el-option>
					</el-select>
					<span @click="getPlanSearch">搜索酒店</span>
				</div>
                 <div v-show="!isShowRomms" class="textRomms">
                    <span style="color:#808080">未能获取到酒店价格，建议进行</span>
                    <span style="color: #52b5f8;text-decoration:underline;  cursor:pointer;" @click="seePriceHander">人工询价</span>
                </div>
				<div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
					v-for="(item, roomListIndex) in roomList" :key="roomListIndex" style="margin-top: 20px" v-show="isShowRomms && item.show">
					<!--左边底部酒店搜索列表模块-->
					<div class="search-list" v-if="tabsShow == 1">
						<div class="search-left-center">
							<div class="search-left">
								<el-image class="img" :src="item.resRoomInfo.imageUrl" alt="暂无图片"  > 
                                          <div slot="error" class="image-slot">
                                             <img :src="errImg" alt="">
                                          </div>     
                                </el-image>  
							</div>
							<div class="search-center">
								<span>{{ item.roomName }}</span>
								<span>
									床型：{{item.resRoomInfo.bedType}} 
									面积：{{ item.resRoomInfo.area }}m²
									楼层{{ item.resRoomInfo.floor }} &nbsp;&nbsp;
									<span class="search-center-span" style="cursor:pointer" @click="lookImages(item.resRoomInfo,2)">查看图片
									</span>
								</span>
								<span>描述：{{item.resRoomInfo.description}}</span>
							</div>
						</div>
						<div class="search-right">
							<div class="right-top">
								<p>￥{{ item.minFacePrice }}</p>
								<p>起</p>
							</div>
							<span class="right-bottom" @click="showItem(item, roomListIndex)">
								<p>
									{{ item.itemShow ? '展开' : '收起' }}
								</p>
								<i :class="[item.itemShow? 'el-icon-arrow-down': ' el-icon-arrow-up',]"></i>
							</span>
						</div>
					</div>
					<!--左边底部酒店房源列表-->
					<div class="search-room" v-if="tabsShow == 1" >
						<div class="room-header">
                            <span style="width: 248px"> 供应商来源 </span>
                            
							<span style="width: 248px"> 产品名称 </span>
							<span style="width: 248px"> 早餐 </span>
							<span style="width: 248px"> 取消规则 </span>
							<span style="width: 248px"> 日均价 </span>
							<!-- <span style="width: 248px"> 总价 </span> -->
							<span style="width: 248px"> 差旅标准 </span>
							<span style="width: 248px"> 房量 </span>
                            
							<span style="width: 280px">
								共{{ item.ratePlans.length  }}个产品
							</span>
						</div>
						<div v-for="(ratePlansItem, ratePlansItemIndex) in item.ratePlans" :key="ratePlansItemIndex" >
							<div class="room-body" v-if="item.itemShow && ratePlansItem.show ">
                                <span style="width: 248px">
									<span class="" v-show="ratePlansItem.bussType == 1 ">嘉享协议</span>
									<span class="" v-show="ratePlansItem.bussType == 2 ">企业托管</span>
									<span class="" v-show="ratePlansItem.bussType != 1 && ratePlansItem.bussType != 2 && ratePlansItem.fyName && ratePlansItem.corpGroup == 1 ">集团托管</span>
									<span class="info_type font14 font-bold black1"
										v-show="ratePlansItem.bussType != 1 && ratePlansItem.bussType != 2 && ratePlansItem.fyName">
										{{ratePlansItem.fyName}}
									</span>
										<!-- ratePlansItem.bussType == 1 || -->
									<span class="ht-detail-xieyi"
										v-show=" ratePlansItem.bussType == 2 || 
										 (ratePlansItem.bussType != 1 && ratePlansItem.bussType != 2 && ratePlansItem.fyName && ratePlansItem.corpGroup == 1 )">
										 协议
									</span>
									
									<el-tooltip effect="dark" :content="'嘉享权益:'+ (ratePlansItem.bookEquity|| '')"  placement="bottom">
									    <span class="jia-xiang-span" style="cursor:pointer;" v-show="ratePlansItem.bookEquity ">嘉享</span>
									</el-tooltip>
									<span class="intergral-span" style="cursor:pointer;" v-show="ratePlansItem.pointsType == 1">嘉享积分</span>

								</span>
								<span style="width: 248px">
									{{ ratePlansItem.rpName }} 
								</span>
								<span style="width: 248px">
									{{ratePlansItem.freeMeal | breakfastTypeName}}
								</span>
                                   <el-tooltip effect="dark" :content="'取消规则'+ ratePlansItem.prepayRule?ratePlansItem.prepayRule.description:''"  placement="bottom">
                                        <span class="tooltip" style="width: 280px; color: #007aff;cursor:pointer;">
                                        {{ shortRules[ratePlansItem.shortRule] }}
								</span>
                            </el-tooltip>
								<span style="width: 248px">
									￥{{ ratePlansItem.averageRate }}
								</span>
								<!-- <span style="width: 280px">
									￥{{ ratePlansItem.averageRate }}
								</span> -->
								<span style="width: 248px; color: #ff6c00">
									<span v-if="tripType==1 && bookStyle==2 && gkfsRole==1">
										{{!ratePlansItem.violate ? '': '违背'}}
									</span>
									<span v-if="tripType==1 && bookStyle==2 && gkfsRole==2 ">
										<span>{{!ratePlansItem.violate ? '': '违背'}}</span>
									</span>
									<span v-if="tripType==1 && bookStyle==1">
										<span>{{!ratePlansItem.violate ? '': '违背'}}</span>
									</span>
								</span>
						
								<span style="width: 248px">
									{{allRoomStatusRules[ratePlansItem.allRoomStatus]}}
								</span>
								<span style="width: 280px" class="last-body">
									<!-- <span>{{ratePlansItem.payment == 1? '现付': '预付'}}</span> -->
									<span v-if="canBook(ratePlansItem)" style="cursor:pointer;" 
										@click="jumpEdit(ratePlansItem, item, ratePlansItem.violate)">预订</span>
									<span class="disabled" v-if="!canBook(ratePlansItem)">预订</span>	
										
								</span>
							</div>
						</div>	

					</div>
				</div>
				<!--左边底部酒店信息-->
				<div class="hotel-info" v-if="tabsShow == 2">
					<p>酒店信息</p>
					<div class="info">
						<span>酒店电话</span>
						<div class="info-right">
							<p>{{ detalisInfo.phone }}</p>
							<span></span>
						</div>
					</div>
					<div class="info">
						<span>酒店设施</span>
						<div class="info-right">
							<p>{{ detalisInfo.generalAmenities }}</p>
							<span></span>
						</div>
					</div>
					<div class="info">
						<span>酒店简介</span>
						<div class="info-right">
							<p>{{ detalisInfo.introEditor }}</p>
							<span></span>
						</div>
					</div>
					<div class="info">
						<span>支持信用卡</span>
						<div class="info-right">
							<p>{{ detalisInfo.creditCards }}</p>
							<span></span>
						</div>
					</div>
				</div>
				<div class="hotel-info" v-if="tabsShow == 3"></div>
			</div>
			<!--右侧模块-->
			<div class="container-right">
				<!--地图模块-->
				<div class="map" @click="dialogMap">
					<img src="@/assets/image/htcnbgc/map.png" alt="" />
				</div>
				<!--差旅模块-->
				<div class="standard" v-if="htCnQuery.tripType == 1">
					<div v-if="htCnQuery.gkfsRole == 1 && bookStyle==2">
						<p>差旅标准</p>
						<span>
							本次预订每间房价不得高于<span style="color: red;">{{htCnCcsqD.currentRoute.fyys||''}}元</span>/夜
						</span>
					</div>
					<div v-if="JSON.stringify(policy) != '{}' && ((htCnQuery.gkfsRole == 2 && bookStyle==2) || (bookStyle==1))">
						<p>差旅标准</p>
						<div class="clbz-clbzText">
							{{ policy.mulPerson == 1 ? '已开启' : '未开启'}}多人差标模式
						</div>
						<div class="clbz-user" v-show="policy.mulPerson != 1">
							本次预订采用<span style="opacity: 1;color: #4089FF; font-size: 13px;">{{ useNameclbz }}</span>的差旅标准
						</div>
						<div class="clbz-user" v-show="policy.mulPerson == 1">
							本次预订采用<span style="opacity: 1;color: #4089FF; font-size: 13px;">入住人{{ policy.mulPersonStandardDesc }}</span>的差旅标准
						</div>
						<span>酒店订房 不超过 <span style="color: red;">{{jdjgbz}}元</span>/间</span>
					</div>
					<div v-if="htCnQuery.gkfsRole == 0 && bookStyle==2">
						<p>差旅标准</p>
						<span>差旅标准未管控</span>
					</div>
				</div>
				
				<!--空港特价模块-->
				<div class="sale">
					<div class="sale-top">
						<div class="top-text">
							<p>推荐低价酒店！</p>
							<span style="text-align: left">100%低于网站价住酒店,客服全程服务</span>
						</div>
						<div class="top-btn" @click="seePriceHander">
							人工询价
						</div>
					</div>
					<div class="sale-bottom">
						<p>附近酒店</p>
						<div class="sale-list" v-for="item in athoerHoterList" :key="item.index">
							<img :src="item.img" alt="" />
							<div class="list-center">
								<p>{{ item.jdmc }}</p>
								<!-- <i class="el-icon-eleme"></i> -->
								<span>相距约{{ item.distance }}公里</span>
								<span>
									<p>￥{{ item.price }}</p>
									<p>起</p>
								</span>
							</div>
							
						</div>
					</div>
					<htCnDeatilsTabs :detalisInfo="detalisInfo" :athoerHoterList="athoerHoterList"
						:dialogShow.sync="dialogShow" />
					<yj-violation :vltShow="visible" :vltReason="clbzText"  :byNumber="byNumber"
						@chooseReason="chooseReason" @cancelReason="cancelReason" />
					<see-price :seePriceShow.sync="seePriceShow" :seePriceInfo="seePriceInfo" />
					<el-image-viewer v-if="imgViewerVisible" :on-close="closeImgViewer" :url-list="imgList"></el-image-viewer>
					<htImgPop :imgArray="typeImgArray" :imgDefaultIdx="showIdx" :htDetails="detalisInfo" v-model="showAllImg"/>
				</div>
			</div>
       
		</div>
	</div>
</template>

<script>
	/* 需要用到的接口    酒店详情接口 http://222.128.98.250:8090/1.0.0/ht/st/hotelDetail
                    酒店房间接口   http://222.128.98.250:8090/1.0.0/ht/st/hotelRoom
                    房间图片接口            http://222.128.98.250:8090/1.0.0/ht/fa/imageList
                    附近酒店接口 http://222.128.98.250:8090/1.0.0/ht/fa/hotelListSearch
                    酒店价格计划查询(床房列表) http://222.128.98.250:8090/1.0.0/ht/fa/hotelPlanSearch
                */
	import mixin from './../../mixin';
import htCnDeatilsTabs from './HtCnComponent/htCnDeatilsTabs';
import htImgPop from './HtCnComponent/htImgPop.vue';
import seePrice from './HtCnComponent/seePrice.vue';

import {
mapGetters,
mapMutations, mapState
} from 'vuex';
import hotelmixin from './../../mixin/models/hotelMixin.js';
	export default {
		name: 'HtCnDetails',
		mixins: [mixin.ccsqdMixin,hotelmixin.hotelCxrListCtrl],
		components: {
			'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer'),
			htCnDeatilsTabs,
			seePrice,
			htImgPop,
		},
		data() {
			// 酒店图片类型名称
			this.imageTypeName =  {
				1: '餐厅',
				2: '休闲',
				3: '会议室',
				5: '外观',
				6: '大堂/接待台',
				8: '客房',
				10: '其他',
				11: '公共区域',
				12: '周边景点',
			}

			return {
				imgList:[],
				imgViewerVisible:false,
				visible: false,
				errImgB:require('@/assets/image/htcnbgc/hotel_zwt.jpg'),
				errImg:require('@/assets/image/htcnbgc/hotel_zwt_s.jpg'),
				clzcStr: '',
				byNumber: '10903',
				reasonCode: '', // 违背编码
				reasonDesc: '', // 违背原因
				hotelRatePlans: {}, //房间信息
				roomItem: {}, // 房间类型信息
				loading: false, //
				objArrImg: [], //
				hotelItem: {}, //列表页传入过来的item
				detalisInfo: {}, //请求接口获取的详情,
				hotelImgs: [],
				roomsImgs: [],
				timeDefaultShow: [],
				dateValue: '',
				isShowRomms:true,
				seePriceInfo: {},
				hotelSelectValue: '',
				roomList: [],
				seePriceShow: false, // 人工询价布尔值
				tabsShow: 1,
				dialogShow: false,
				athoerHoterParams: '',
				athoerHoterList: [],
				shortRules: {
					11: '无需担保',
					12: '限时担保',
					13: '强制担保',
					21: '免费取消',
					22: '限时取消',
					23: '不可取消',
				},
				allRoomStatusRules: {
					0: '充足',
					1: '紧张',
					2: '满房',
					3: '需确认',
				},

				options: [{
						fyid: '',
						label: '全部',
					},
					{
						fyid: '12004',
						label: '艺龙',
					},
					{
						fyid: '12005',
						label: '道旅',
					},
					{
						fyid: '12006',
						label: '亚朵',
					},
					{
						fyid: '12007',
						label: '华住',
					},
					{
						fyid: '12008',
						label: '锦江',
					},
				],
				value: '',
				// pickerOptions: {
				// 	disabledDate(time) {
				// 		return time.getTime() < Date.now() - 8.64e7
				// 	},
				// },
				showAllImg: false, // 显示所有图片
				typeImgArray: [], // 分类好的图片
				showIdx: 0, // 显示第几张图片
				travelStandardsFilter:false,
				p2721tips: 0, // 是否开启非协议价格下单提示
			}
		},
		computed: {
			...mapState({
				htCnQuery: (state) => state.htCn.htCnQuery,
				htCnCcsqD: (state) => state.htCn.htCnCcsqD,
				htCnPolicy: (state) => state.htCn.htCnPolicy,

				// ccsqdInfo: (state) => state.common.ccsqdInfo, // 出差申请单信息
				cxrList: state => state.htCn.cxrList, // 出行人
				cusRoomsState: state => state.htCn.cusRoomsState,

			}),
			...mapGetters({
				getVipParamterByParNo: 'common/getVipParamterByParNo',
			}),
			// 日期最小，和最大值对象
			bookDateRange() {
				// 方法来自于mixins
				let dateRangeObj = this.getBookDateRange(10903, this.htCnQuery.tripType, 90, this.htCnCcsqD.ccsqdId, this.htCnCcsqD.routeId);
				return dateRangeObj
			},
			gkfsRole() {
				return this.htCnQuery.gkfsRole //0 不管控  1出差申请单管控  2差旅标准管控
			},
			clbzText() {
				return `差旅标准：本次预订每间房价不得高于 ¥${this.jdjgbz}元/间夜`
			},
			bookStyle() {
				// 1单订模式  2出差申请单模式
				let bookStyle = this.$common.sessionget('user_info').vipCorp.bookStyle
				return bookStyle
			},
			tripType() {
				return this.htCnQuery.tripType /* 默认当前因公因私*/
			},
			// 按差旅标准管控 酒店价格标准 
			jdjgbz() {
				var price
				if(JSON.stringify(this.htCnPolicy)!='{}'){
					price = this.htCnPolicy.policy.jdjgbz || ''
				}
				return price || ''
			},
			// 出差人
			useNameclbz(){
				return this.htCnQuery.useNameclbz;
			},
			policy() {
				var policy
				if(JSON.stringify(this.htCnPolicy)!='{}'){
					policy = this.htCnPolicy.policy || {}
				}
				return policy || {}
			},
			// 按差旅标准管控  管控方式 1提醒 2不可预定
			gkfs(){
				var gkfs
				if(JSON.stringify(this.htCnPolicy)!='{}'){
					gkfs = this.htCnPolicy.policy.gkfs || ''
				}
				return gkfs || ''
			},
            objArrImgSplice(){
                let arr=[]
                let splicPic=this.objArrImg.slice(1, 7)
                for(let i=0 ;i<6;i++){
                    let obj={}
                    if(splicPic[i]){
                        obj.img=splicPic[i]
                    }else{
                        obj.img=''
                    }
                    arr.push(obj)
                }
                return arr
            }
		},
		created() {
			this.hotelItem = this.$route.query;
			this.timeDefaultShow.push(
				this.hotelItem.checkInDate,
				this.hotelItem.checkOutDate
			)
			this.dateValue = this.timeDefaultShow
			this.getHotelImage()
			this.getPlanSearch()
			this.getDetailsInfo()
			this.getP2721tips()
		},
		methods: {
			...mapMutations({
				SAVE_HOTELINFO: 'htCn/SAVE_HOTELINFO',
				SAVE_ROOMINFO: 'htCn/SAVE_ROOMINFO',
				SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY',
				SET_HTCN_POLICY: 'htCn/SET_HTCN_POLICY',
			}),

			getP2721tips(){
				let userInfo = this.$common.sessionget('user_info');
				var obj = {
					codes:'2721',
					corpId :userInfo.empInfo.corpId
				}
				this.$conn.getConn('user.getVipParameter')(obj,res => {
					var list = res.data || [];
					if(list.length!=0){
						this.p2721tips = list[0].value1 || 0;
					}
				})
			},
			// 符合差标
			changetravelStandardsFilter(){
				this.travelStandardsFilter = !this.travelStandardsFilter;
				
				// this.searchAll(true)
				this.filterRooms();
			},

			//筛选
			filterRooms() {
	
				/**
				 * 	1.判断当前房型是否显示， 受【当前房型价格】显示影响，当存在满足条件的可以显示
				 *  2.判断当前房型的价格是否显示
				 * 
				 * **/ 

				 this.roomList = this.roomList.map(roomItem=>{
					let newRoomItem =  {
						...roomItem,
						
					}
					newRoomItem.ratePlans = roomItem.ratePlans.map(planItem=>{
						let show = true;
						if(this.travelStandardsFilter && planItem.violate){
							show = false;
						}
						return {
							...planItem,
							show
						}
					})
					let filterRatePlans = newRoomItem.ratePlans.filter(ratePlansItem=>{
						return ratePlansItem.show
					})
					newRoomItem.show = filterRatePlans.length>0

					return newRoomItem
				 })

				 console.log( this.roomList)
			},



			// 选择右边6张的一个图片
			selectImg(idx) {
				this.showAllImg = true;
				this.showIdx = idx;
			},
			canBook(ratePlansItem) {
				// 满房不可预订
				if (ratePlansItem.allRoomStatus == 2) {
					return false;
				}
				// 不违背 直接可预订
				if (!ratePlansItem.violate) {
					return true;
				}
				// 违背 如果多人差标管控是不可预订则不能预订
				var htCnPolicy = this.htCnPolicy ? this.htCnPolicy.policy : {};
				// mulPerson == 1 开启了多入住人差标匹配
				if (!!htCnPolicy && htCnPolicy.mulPerson == 1) {
					// mulPersonControl == 2 不可预订
					if (htCnPolicy.mulPersonControl == 2) {
						return false;
					}
				}
				return true;
			},
			lookImages(info,type){
				if(type==2){
					if(info.urlImgs){
						this.imgList=info.urlImgs
						this.imgViewerVisible=true
						this.stopMove()
					}else{
						thi
					}
				}else{
					this.imgList=info
					this.imgViewerVisible=true
					this.stopMove()
				}
			},
			pickerOptions(minDay,maxDay){
				return {
					//定义不可选择时间 暂无预约功能 查找有效日期
					// 查询出差申请单预订行程日期可否变更 0不可更改  1 按行程更改 2 出差申请单日期范围更改
					disabledDate(time) {
						let minTime = new Date(new Date(minDay).toLocaleDateString()).getTime();
						let maxTime = new Date(new Date(maxDay).toLocaleDateString()).getTime();
						return time.getTime() < minTime  || time.getTime() > maxTime;
					},
				}
			},
			closeImgViewer(){
					this.imgViewerVisible=false
					this.move()
			},
			// 停止页面滚动
			stopMove () {
					const m = (e) => { e.preventDefault() };
					document.body.style.overflow = 'hidden';
					document.addEventListener("touchmove", m, false); // 禁止页面滑动
			},
			// 开启页面滚动
			move () {
					const m = (e) => { e.preventDefault() };
					document.body.style.overflow = 'auto';
					document.removeEventListener("touchmove", m, true);
			},
			chooseReason(val) {
				this.reasonDesc = val.reasonDesc;
				this.reasonCode = val.reasonCode;
				val.violateItem = this.clbzText;
				val.violateitemCode = '11109';
				this.SET_HTCN_QUERY(val);
				this.visible = false;
				let obj = {
					jdid: this.hotelItem.jdid,
					checkInDate: this.dateValue[0],
					checkOutDate: this.dateValue[1],
					roomId: this.roomItem.roomId,
					payment: this.hotelRatePlans.payment,
					ratePlanId: this.hotelRatePlans.ratePlanId,
					fyid: this.hotelRatePlans.fyid,
					hotelId: this.hotelItem.jdid,
					bussType: this.hotelRatePlans.bussType,
					delayOrdreType:this.$route.query.delayOrdreType , // 续住标识
					cityId: this.hotelItem.cityId,
					priceFromInfo: this.hotelRatePlans.priceFromInfo,
					minPriceRatePlanId:this.hotelRatePlans.minPriceRatePlanId,
				}
				var htCnCcsqD = this.htCnCcsqD || {};
				// 出差申请单id
				obj.ccsqdId = htCnCcsqD.ccsqdId || '';
				obj.routeId = htCnCcsqD.routeId || '';

				this.SAVE_ROOMINFO(this.roomItem) //存储单个房间的数据进vux

				this.$router.push({
					name: 'HtCnEdit',
					query: obj,
				})

			},
			cancelReason(bool) {
				this.visible = bool;
			},

			seePriceHander() {
				this.seePriceInfo.checkInDate = this.dateValue[0]
				this.seePriceInfo.checkOutDate = this.dateValue[1]
				this.seePriceInfo.jdmc = this.detalisInfo.jdmc
				this.seePriceInfo.address = this.detalisInfo.address
				this.seePriceInfo.phone = this.detalisInfo.phone
				this.seePriceInfo.jdid = this.hotelItem.jdid,
					this.seePriceShow = !this.seePriceShow
			},
			jumpEdit(val,item){
				
				item.choosePricePlanItem = val ;
				this.hotelRatePlans = val;
				this.roomItem = item;
				let weibei = val.violate;

				// 如果不是【仅查询模式】，需要是否可以预订
				if (this.htCnQuery.reqFrom != 'onlySearch') {
					if (weibei && this.tripType === 1 && this.gkfs == 2) {
						this.$alert('当前房型已超标,不能预订,如需预订请您电话联系酒店预订人员', '', {
							confirmButtonText: '确定',
						});
						return false;
					}
				}
				const nextBook = () => {
					let queryObj = {
						hotelId: this.hotelItem.jdid, // 酒店id不能为空
						checkInDate: this.dateValue[0], // 入住日期
						checkOutDate: this.dateValue[1], // 离店日期
						roomId: this.roomItem.roomId, // 房型id
						ratePlanId: this.hotelRatePlans.ratePlanId, // 价格计划id
						payment: this.hotelRatePlans.payment, // 支付方式 1-现付 2-预付
						// fyid: this.hotelRatePlans.fyid, // 供应商id
					}
					this.$conn.getConn('htbook.hotelCheckMinPrice')(queryObj, (res) => {
						let data = res.data || {};
						if (data.showTips == 1) {
							let tips = `系统匹配到房型下最低价格为:${data.minPrice || ''},是否确认放弃该优惠价格继续预订？`;
							this.$confirm(tips, '温馨提示', {
								confirmButtonText: '继续预订',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.roomItem.choosePricePlanItem.minPriceRatePlanId = data.minPriceRatePlanId;

								this.hotelRatePlans.minPriceRatePlanId = data.minPriceRatePlanId;
								this.jumpEditNext(this.hotelRatePlans, this.roomItem)
							}).catch(() => {

							});

						} else {
							this.hotelRatePlans.minPriceRatePlanId = data.minPriceRatePlanId;
							this.jumpEditNext(this.hotelRatePlans, this.roomItem)
						}
					}, (err) => {
						this.$message({
							type: 'error',
							message: err.tips,
						})
					})
				}

				// 是否开启非协议价格下单提示
				if (!(this.hotelRatePlans.corpGroup == 1 || this.hotelRatePlans.bussType == 2) && this.p2721tips == 1) {
					let tips = `您当前选择的为非企业的协议价格，是否继续预订`;
					this.$confirm(tips, '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						nextBook()
					});
					return;
				}
				nextBook()
			},
			jumpEditNext(val, item) {
				// 当申请单预算管控的时候当酒店违背的时候，全部无法预定
				// 按照差旅标准管控的时候，按照后台设置的差旅标准管控，当酒店违背的时候，按照后台设置来看是只做提醒，还是不能预定
				// 不管控的情况下，可以任选，不受限制，且页面不会出现违背差旅标准字段，

				// 出差申请单模式 因公 按出差申请单管控  当前房间价格 大于 申请单费用预算  
				if (this.tripType == 1 && this.gkfs == 2 && !!val.violate) {
					this.$alert(
						// `差旅标准：本次预订每间房价不得高于 ¥${this.htCnCcsqD.currentRoute.fyys}元/间夜;如需预订请您电话联系预订人员`,
						"当前房型已超标,不能预订,如需预订请您电话联系酒店预订人员",
						'', {
							confirmButtonText: '确定',
						}
					)
					return;
				} 

				if (!!val.violate && this.tripType === 1 && (this.gkfs != 3 && this.gkfs != 4)) {
					this.visible = true;
				} else {
					let obj = {
						jdid: this.hotelItem.jdid,
						checkInDate: this.dateValue[0],
						checkOutDate: this.dateValue[1],
						roomId: item.roomId,
						payment: val.payment,
						ratePlanId: val.ratePlanId,
						fyid: val.fyid,
						hotelId: this.hotelItem.jdid,
						bussType: val.bussType,
						priceFromInfo: val.priceFromInfo,
						delayOrdreType:this.$route.query.delayOrdreType , // 续住标识
						minPriceRatePlanId:val.minPriceRatePlanId,
					}
					this.SAVE_ROOMINFO(item) //存储单个房间的数据进vux
					// this.SAVE_HOTELINFO(this.hotelItem)
					this.$router.push({
						name: 'HtCnEdit',
						query: obj,
					})
				}
				// else if (this.tripType == 1 && this.bookStyle == 1 && !!val.violate) {
				// 	if(!this.reasonDesc){
				// 		this.visible = true
				// 	}
				// 	// 因公 单订模式  this.jdjgbz < val.averageRate表示差旅标准 大于 房间单价   reasonDesc 是否选择了违背原因^
					
				// } 
				// else if (this.tripType == 1 && this.bookStyle == 2 && this.gkfsRole == 2) {
				// 	// 因公 出差申请单模式 按照差旅标准管控
				// 	// gkfs (string): 管控方式,1是 只做提醒,2是 不允许预订 ,
				// 	if (this.gkfs == 2 && !!val.violate) {
				// 		// 不允许预定
				// 		this.$alert(`差旅标准：本次预订每间房价不得高于 ¥${this.htCnPolicy.policy.jdjgbz}元/间夜;如需预订请您电话联系预订人员`, '', {
				// 			confirmButtonText: '确定',
				// 		})
				// 		return 
				// 	} else if (!this.reasonDesc && !!val.violate && this.gkfs != 3 && this.gkfs != 4) {						
				// 		this.visible = true
				// 	}  else {
				// 		let obj = {
				// 			jdid: this.hotelItem.jdid,
				// 			checkInDate: this.dateValue[0],
				// 			checkOutDate: this.dateValue[1],
				// 			roomId: item.roomId,
				// 			payment: val.payment,
				// 			ratePlanId: val.ratePlanId,
				// 			fyid: val.fyid,
				// 			hotelId: this.hotelItem.jdid,
				// 			bussType: val.bussType,
				// 			delayOrdreType:this.$route.query.delayOrdreType , // 续住标识
				// 			priceFromInfo: val.priceFromInfo,
				// 			minPriceRatePlanId:val.minPriceRatePlanId,
				// 		}
				// 		this.SAVE_ROOMINFO(item) //存储单个房间的数据进vux
				// 		this.$router.push({
				// 			name: 'HtCnEdit',
				// 			query: obj,
				// 		})
				// 	}
				// }  else {
				// 	let obj = {
				// 		jdid: this.hotelItem.jdid,
				// 		checkInDate: this.dateValue[0],
				// 		checkOutDate: this.dateValue[1],
				// 		roomId: item.roomId,
				// 		payment: val.payment,
				// 		ratePlanId: val.ratePlanId,
				// 		fyid: val.fyid,
				// 		hotelId: this.hotelItem.jdid,
				// 		bussType: val.bussType,
				// 		priceFromInfo: val.priceFromInfo,
				// 		delayOrdreType:this.$route.query.delayOrdreType , // 续住标识
				// 		minPriceRatePlanId:val.minPriceRatePlanId,
				// 	}
				// 	this.SAVE_ROOMINFO(item) //存储单个房间的数据进vux
				// 	// this.SAVE_HOTELINFO(this.hotelItem)
				// 	this.$router.push({
				// 		name: 'HtCnEdit',
				// 		query: obj,
				// 	})
				// }
			},
			dialogMap() {
				this.dialogShow = true
			},
			handleTab(val) {
				this.tabsShow = val
			},
			showItem(item, index) {
				this.roomList[index].itemShow = !item.itemShow;
			},
			getDetailsInfo() {
				this.$conn
					.getConn('htbook.hotelDetail')({
						jdid: this.hotelItem.jdid,
					},(res) => {
						this.detalisInfo = res.data
						this.athoerHoterParams = {
							checkInDate: this.hotelItem.checkInDate,
							checkOutDate: this.hotelItem.checkOutDate,
							cityId: this.detalisInfo.cityId,
							count: 10,
							distance: 3,
							exclude: 1,
							jdids: this.detalisInfo.jdid,
							lat: this.detalisInfo.baiduLat,
							lon: this.detalisInfo.baiduLon,
							pageNum: 1,
							searchType: 3,
							starRate: this.detalisInfo.starRate,
						}
						if(this.athoerHoterParams.lat && this.athoerHoterParams.lon){
							this.getAthoerHoter(this.athoerHoterParams);
						}
						
					},(err) => {
						this.$message({
							type: 'error',
							message: err.tips,
						})
					})
			},

			getRommsInfo() {
				//获取房间信息
				this.$conn.getConn('htbook.hotelRoom')({
						hotelId: this.hotelItem.jdid,
						findFrom: 1,
						roomId: '',
					},(res) => {
						let resRooms = res.data;
						this.roomList.map((item) => {
							item.resRoomInfo = resRooms.filter((e) => {
								return e.fxid == item.roomId
							})[0]
						})
						this.roomList.forEach(item=>{
								this.roomsImgs.forEach(i=>{
										if(item.resRoomInfo.fxid==i.fxid){
												item.resRoomInfo.urlImgs=i.url
										}
								})
						})
					})
			},

			getAthoerHoter(params) {
				this.$conn.getConn('htbook.hotelListSearch')(params,(res) => {
						this.athoerHoterList = res.data.list || []
					},(err) => {
						// this.$message({
						// 	type: 'error',
						// 	message: err.tips,
						// })
					})
			},
			getHotelImage() {
				let self = this;
				this.$conn.getConn('htbook.hotelImage')({
						jdid: this.hotelItem.jdid,
						imgSize: 1,
						imgType: '1,2,3,5,6,8,10,11,12',
					},(res) => {
						var data = res.data || {};
						
						data.hotelImgs = data.hotelImgs || [];
						data.roomsImgs = data.roomsImgs || [];
						//酒店图片排序
						let hotelImgsObj = {};
						let imgsArray = [];
						const unique = (arr) => {
							let newArray = arr.filter((item, index) => {
								return arr.indexOf(item) === index
							})
							return newArray
						}

						for (let i = 0; i < data.hotelImgs.length; i++) {
							let type = data.hotelImgs[i].type;
							let list = data.hotelImgs[i].url;
							hotelImgsObj[type] = list;
							imgsArray.push({
								title: self.imageTypeName[type],
								list: unique(list)
							})
						}
	
						// 1 - 餐厅 (Restaurant) 2 - 休闲 (Recreation Facilities) 3 - 会议室 (Meeting/Conference) 5 - 外观 (Exterior) 6 - 大堂/接待台 (Lobby/ Reception) 8 - 客房 (Guest Room) 10 - 其他 (Other Facilities) 11 - 公共区域 (Public Area) 12 - 周边景点 (Nearby Attractions)
						let sort = ["5", "10", "6", "11", "1", "2", "3", "8", "12"];
						let hotelImgs = [];

						for (let c = 0; c < sort.length; c++) {
							if (hotelImgsObj[sort[c]]) {
								hotelImgs = hotelImgs.concat(hotelImgsObj[sort[c]]);
							}
						}
						imgsArray.unshift({
							title: '全部',
							list: hotelImgs,
						});
						self.typeImgArray = imgsArray;
												
						this.objArrImg = hotelImgs;
//						this.hotelImgs.forEach((item) => {
//							item.url.forEach((itemImg) => {
//								this.objArrImg.push(itemImg)
//							})
//						})

						this.detalisInfo.img = this.objArrImg[0];
						this.hotelImgs = data.hotelImgs
						this.roomsImgs = data.roomsImgs
					},(err) => {
						this.$message({
							type: 'error',
							message: err.tips,
						})
					})	
			},
			getPlanSearch() {
				this.loading = true
				let params = {
					checkInDate: this.dateValue[0],
					checkOutDate: this.dateValue[1],
					hotelId: this.hotelItem.jdid,
					fyid: this.hotelSelectValue,
					tripType: this.tripType, // 因公因私
				};
			
				params.cityId = this.hotelItem.cityId;
				
			// cxrList: state => state.htCn.cxrList, // 出行人
			// cusRoomsState: state => state.htCn.cusRoomsState,
			
			   // 2023/06/09  13:11 陈伟说： 当没有房间数时，默认按照 一人一间处理
				// 入住人房间：
				params.roomNum = this.cusRoomsState.length || this.cxrList.length;
				// 入住人数量
				params.checkInNum = this.cxrList.length;
				
				var htCnCcsqD = this.htCnCcsqD || {};
				
				// 出差申请单id
				params.ccsqdId = htCnCcsqD.ccsqdId || '';
				params.routeId = htCnCcsqD.routeId || '';
				
				// 最低出行人职级
				if (this.$store.state.htCn.cxrList) {
					let clbzObj = this.getClbzParamMap();
					params = {
						...params,
						...clbzObj
					}

					// let minEmpRank = 99999;
					// let allRank = [];
					// let ids = [];
					// let roomEmpMap = {};
					// let userInfo = this.$common.sessionget('user_info');
					// params.corpId = userInfo.empInfo.corpId;
					// this.$store.state.htCn.cxrList.forEach(cxrItem =>  {
					// 	if (cxrItem.empRank == void 0) cxrItem.empRank = 0;
					// 	if (cxrItem.cxrType != 2) {
					// 		ids.push(cxrItem.empId);
					// 		allRank.indexOf(cxrItem.empRank) == -1 && allRank.push(cxrItem.empRank);
					// 		if(cxrItem.empRank < minEmpRank){
					// 			minEmpRank = cxrItem.empRank;
					// 		}
					// 	} else {
					// 		// 2023-10-30 bug号：11372 陈伟说 外来人使用预订人差标 传入预订人的id 
					// 		ids.push(userInfo.empInfo.empId)
					// 		allRank.indexOf(userInfo.empInfo.empRank) == -1 && allRank.push(userInfo.empInfo.empRank);
					// 		if(userInfo.empInfo.empRank < minEmpRank){
					// 			minEmpRank = userInfo.empInfo.empRank;
					// 		}
					// 	}
						
					// });
					// ids.forEach((empId, empIdx) => {
					// 	if (empIdx < 5) {
					// 		roomEmpMap[empIdx + 1] = [empId];
					// 	} else {
					// 		roomEmpMap[empIdx % 5 + 1].push(empId);
					// 	}
					// });
					// params.roomEmpMap = roomEmpMap;

					// if (minEmpRank != 99999) {
					// 	params.minEmpRank = minEmpRank;
					// 	params.allRank = allRank.join(',');
					// }
					// params.travelerIds = ids;
				}


				this.$conn.getConn('htbook.planSearch2')(params, (res) => {
					if(res.data){
						let travelPolicy = res.data.travelPolicy;
						// policy
						this.SET_HTCN_POLICY({policy: travelPolicy});
						res.data.rooms.forEach((e) => {
							e.itemShow = true
						})
						let list = res.data.rooms;
						this.roomList = [];
						this.roomList = list.map((item) => {
								item.resRoomInfo = {};
								item.ratePlans = (item.ratePlans || []).map((planItem)=>{
									return {
										...planItem,
										prepayRule:planItem.prepayRule || {}
									}
								});
								
								return item
						})	
						this.isShowRomms=true			
						this.loading = false
						this.getRommsInfo() //获取到房间信息后调用

						this.filterRooms();

					}else{
						this.loading = false 
						this.isShowRomms=false
					}
				},(err) => {
					this.loading = false
					this.$message({
						type: 'error',
						message: err.tips,
					})
				})	
			},
		},
		filters: {
			breakfastTypeName(val=0) {
				let retStr = '';
				if (val == 1 || val == 2) {
					retStr = val + '份早';
				} else if (val > 2) {
					retStr = '多份早';
				} else {
					retStr = '不含早'
				}
				return retStr
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">

	.clickbtn {
	border: 1px solid #007aff;
	background-color: #007aff;
	color: #fff;

	}
	.clickbtn.el-button:focus, .clickbtn.el-button:hover {
		color: #fff;
		border: 1px solid #007aff;
		background-color: #007aff;
	}
	body {
		overflow: hidden;
	}

	html {
		overflow-y: scroll;
	}

	html {
		overflow: auto;
	}

	.tooltip {
		position: relative;
		display: inline-block;
	}
	.ht-detail-xieyi{
		display: inline-block;
		    background-color: #007AFF;
		    color: white;
		    font-size: 12px;
		    font-size: 10px;
		    padding: 0 3px;
		    border-radius: 4px;
	}
    .textRomms{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        min-height: 120px;
        margin-top: 20px;
    }
	.tooltip .tooltiptext {
		visibility: hidden;
		// width: 120px;
		background-color: #fff;
		color: #000000;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		border: 1px solid #000000;
		/* 定位 */
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 50%;
		margin-left: -60px;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
	}

	.to_active {
		color: #327bdc;
		font-weight: 600;
	}

	.tabs {
		width: 100px;
		display: inline-block;
		line-height: 40px;
	}
    .errImg{
        width: 100%;
        height: 100%;
    }
	.jia-xiang-span{
		display: inline-block;
		padding: 2px 2px;
		color: #007aff;
		border: 1px solid #007aff;
		border-radius: 4px;
		font-size: 12px;
		margin: 2px;
	}

	.intergral-span {
		display: inline-block;
		padding: 2px 2px;
		color: #ff6605;
		border: 1px solid #ff6605;
		border-radius: 3px;
		font-size: 12px;
		margin: 2px;
	}

	.ht-detail {
		padding-bottom: 100px;
		background-color: #f0f0f0;
        height: 100%;
		.container {
			width: 1200px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			background-color: #f0f0f0;

			.container-left {
				width: 856px;
				height: 100%;
				background-color: #f0f0f0;

				.hotel-imgs {
					width: 100%;
					height: 435px;
					background-color: #fff;
					margin-top: 20px;
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					padding: 20px;

					.imgs-top {
						display: flex;
						align-items: center;

						p {
							font-size: 20px;
							font-weight: bold;
							margin-right: 20px;
						}

						img {
							width: 20px;
							height: 20px;
						}
					}

					.imgs-center {
						display: flex;
						margin-top: 6px;
						margin-bottom: 24px;
						align-items: center;
					}

					.imgs-bottom {
						display: flex;
						height: 320px;

						.imgs-left {
							position: relative;

							.img {
								width: 504px;
								height: 100%;
							}

							span {
								position: absolute;
								bottom: 20px;
								right: 10px;
								color: #fff;
								cursor: pointer;
							}
						}

						.ims-right {
							display: flex;
							flex-wrap: wrap;
                            flex: 1;
							.img:nth-child(3) {
								margin-top: 10px;
								margin-bottom: 10px;
							}

							.img:nth-child(4) {
								margin-top: 10px;
								margin-bottom: 10px;
							}

							.img {
								margin-left: 10px;
								width: 46%;
								height: 31%;
							}
						}
					}
				}

				.left-tabs {
					display: flex;
					width: 856px;
					height: 65px;
					box-sizing: border-box;
					padding: 20px;
					background: #ffff;
					margin-top: 16px;
					margin-bottom: 16px;

					span {
						margin-right: 40px;
						font-size: 18px;
						font-weight: bold;
						cursor: pointer;
					}
				}

				.home-search span {
					font-size: 16px;
					color: #ffff;
					padding: 5px;
					background-color: #007aff;
					height: 32px;
					border-radius: 5px;
					margin-left: 5px;
					cursor: pointer;
				}

				.search-list {
					width: 100%;
					background: #fff;
					box-sizing: border-box;
					padding: 20px;
					display: flex;
					justify-content: space-between;

					.search-left-center {
						display: flex;
						.search-left {
							.img {
								width: 85px;
								height: 85px;
							}
						}

						.search-center {
							margin-left: 20px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: flex-start;
                            .search-center-span{
                                font-size: 14px;
								color: #007aff;
                            }
							span  {
							     	font-size: 14px;
								color: #808080;
							}

							// span:nth-child(1) {
							// 	font-size: 18px;
							// 	color: black;
							// 	font-weight: bold;
							// }
						}
					}

					.search-right {
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.right-top {
							display: flex;
							align-items: center;

							p:nth-child(1) {
								font-size: 24px;
								color: #007aff;
							}

							p:nth-child(2) {
								font-size: 12px;
								color: #808080;
							}
						}

						.right-bottom {
							width: 55px;
							height: 29px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px solid #f0f0f0;
							cursor: pointer;
						}
					}
				}

				.search-room {
					background-color: #fff;
					box-sizing: border-box;
					// width: 856px;
					padding: 20px;
				
					.room-header {
						width: 100%;
						height: 40px;
						background-color: #e6e6e6;
						color: #808080;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						padding: 12px 8px 11px 12px;
					}

					.room-body:hover {
						background-color: #e6e6e6;
					}

					.room-body {
						width: 100%;
						height: 65px;
						background-color: #fff;
						display: flex;
						align-items: center;
						box-sizing: border-box;
						padding: 12px 8px 11px 12px;
						border-bottom: 1px solid #f0f0f0;

						.last-body {
							span:nth-child(2) {
								width: 41px;
								height: 21px;
								background-color: #ffefe4;

								font-size: 12px;
								color: #ff6c00;
								box-sizing: border-box;
								padding: 5px;
								margin-right: 10px;
							}

							span:nth-child(1) {
								width: 52px;
								height: 35px;
								background-color: #ff6c00;

								font-size: 14px;
								color: #fff;
								box-sizing: border-box;
								padding: 10px;
							}
						 	.disabled {
								background-color: #ccc !important;
							}
						}
					}
				}

				.hotel-info {
					width: 855px;
					background-color: #fff;
					box-sizing: border-box;
					padding: 20px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					margin-top: 20px;

					p {
						font-size: 18px;
						font-weight: bold;
					}

					.info {
						width: 100%;
						color: #424242;
						font-size: 12px;
						display: flex;
						margin-top: 15px;

						span {
							width: 100px;
						}

						.info-right {
							width: 100%;
							margin-left: 50px;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: flex-start;

							p {
								color: #424242;
								font-size: 12px;
								text-align: left;
								margin-bottom: 20px;
							}

							span {
								width: 100%;
								height: 1px;
								background-color: #f0f0f0;
							}
						}
					}
				}
			}

			.container-right {
				margin-top: 20px;
				width: 368px;
				//   height: 100%;
				margin-left: 16px;
				display: flex;
				flex-direction: column;
				background-color: #f0f0f0;

				//   background: pink;
				.map {
					width: 100%;
					height: 100px;
					background-color: #fff;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.standard {
					width: 100%;
					background-color: #fff;
					margin-top: 20px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					box-sizing: border-box;
					padding: 20px;
					justify-content: space-between;
					text-align: left;
					p {
						font-size: 18px;
						font-weight: bold;
						margin-bottom: 15px;
					}

					span {
						font-size: 12px;
						
					}
					.clbz-clbzText {
						font-size: 13px;
						font-weight: bold;
						margin: 5px 0;
					}

					.clbz-user {
						font-size: 13px;
						font-weight: bold;
						margin: 5px 0;
					}

				}

				.sale {
					display: flex;
					flex-direction: column;
					box-sizing: border-box;
					padding: 20px 20px 66px 20px;
					background-color: #fff;
					margin-top: 20px;
					.sale-top {
						display: flex;
						align-items: center;
						padding-bottom: 24px;
						border-bottom: 1px solid #f0f0f0;

						.top-text {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							margin-right: 17px;

							p {
								font-size: 18px;
								font-weight: bold;
							}

							span {
								font-size: 12px;
								margin-top: 3px;
							}
						}

						.top-btn {
							width: 80px;
							height: 36px;
							background-color: #fff;
							border: 1px solid #007aff;
							color: #007aff;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					.sale-bottom {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						margin-top: 20px;

						p {
							font-size: 18px;
						}

						.sale-list {
							display: flex;
							margin-top: 20px;
							width: 368px;
							img {
								width: 95px;
								height: 65px;
							}

							.list-center {
								margin-left: 10px;
								display: flex;
								flex-direction: column;
								
								align-items: flex-start;
								//   display: -webkit-box;
								width: 225px;

								P {
									color: #000000;
									font-size: 15px;
									text-align: left;
								}

								span {
									color: #808080;
								}
							}

							span {
								display: flex;
								align-items: flex-end;

								p:nth-child(1) {
									font-size: 18px;
									color: #007aff;
								}

								p:nth-child(2) {
									font-size: 12px;
									color: #808080;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
